<template>
	<div class="sec">
		<Carousel
			ref="carousel"
			v-bind="config"
			dir="ltr"
			class="rec-wrap profiles"
			@update:currentSlideIndex="val => currentIndex = val"
		>
			<img
				v-for="(person, i) in computedRecommendations"
				:key="person._id"
				:index="i"
				:src="person.profile.imageURL"
				class="rec-image"
				:alt="person.name"
				@click="carousel?.slideIntoView(i)"
			>
		</Carousel>
		<div id="quote" class="rec-wrap quote">
			<img alt="" src="@/assets/quotation-marks.svg" class="quotation-mark">
			<div style="position: relative">
				<div
					class="quote-title" :class="{ activeQuote: currentIndex === i }"
					v-for="(person, i) in computedRecommendations" :key="person._id"
				>
					<p class="txt24">
						{{ person.quote }}
					</p>
					<p class="rec-name">
						{{ person.name }}
					</p>
				</div>
			</div>
		</div>
		<div class="center-txt" style="position: relative; z-index: 1">
			<router-link to="/recommendations" class="small-link">
				צפו בכל ההמלצות
			</router-link>
		</div>
	</div>
</template>

<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';

import { Carousel } from 'bgl-v-owl-carousel';
import { onMounted } from 'vue';
import { localData } from '@/data';

const config = {
	// loop: true,
	margin: 0,
	nav: false,
	center: true,
	dots: false,
	// transitionStyle: 'fade',
	// items,
	responsive: {
		0: {
			items: 3,
		},
		560: {
			items: 5,
		},
		767: {
			items: 7,
		},
	},
};

const computedRecommendations = _computed(() => [...localData.recommendations, ...localData.recommendations]);
const carousel = _ref<typeof Carousel>();
const currentIndex = _ref(0);

onMounted(() => {
	setTimeout(() => {
		carousel.value?.slideIntoView(4);
	}, 300);
});
</script>

<style scoped>
.rec-wrap.profiles {
	max-width: 960px;
	margin-bottom: -20px;
}

.rec-wrap.quote {
	max-width: 720px;
}

.sec {
	width: 100vw;
	/* overflow: hidden; */
}

.quote-title {
	opacity: 0;
	transition: all ease 0.4s;
	width: 100%;
	flex-direction: column;
	position: absolute;
}

.quote-title.activeQuote {
	opacity: 1;
	position: relative;
}

.owl-carousel .owl-item img {
	width: 80px;
}

.sec {
	overflow: hidden;
	max-width: 100%;
}

@media screen and (max-width: 767px) {
	.rec-wrap.profiles {
		max-width: 100%;
		margin-bottom: -20px;
	}
}
</style>

<style>
.profiles .owl-stage .owl-item {
	display: flex;
	justify-content: center;

}

.rec-wrap.profiles>div {
	/* padding: 40px 0; */
}

.active.center .rec-image {
	opacity: 1;
	transform: scale(1.3);
	border: 1px solid #b1b1b1;
}
.profiles .owl-carousel .owl-item{
	padding: 1rem;
}
</style>
