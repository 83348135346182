import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home.vue';

const meta = {};

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/', name: 'Home', component: Home, meta,
		},
		{
			path: '/books', name: 'Books', component: () => import('@/views/Books.vue'), meta,
		},
		{
			path: '/leades', name: 'Leades', component: () => import('@/views/Leades.vue'), meta,
		},
		{
			path: '/about', name: 'About', component: () => import('@/views/About.vue'), meta,
		},
		{
			path: '/terms', name: 'Terms', component: () => import('@/views/Terms.vue'), meta,
		},
		{
			path: '/accessibility',
			name: 'Accessibility',
			component: () => import('@/views/accessibility.vue'),
			meta,
		},
		{
			path: '/recommendations', name: 'Recommendation', component: () => import('@/views/Recommendation.vue'), meta,
		},
		{
			path: '/recommendation/:id',
			name: 'RecommendationPage',
			component: () => import('@/views/Recommendationpage.vue'),
			meta,
		},

		{
			path: '/cart', name: 'Cart', component: () => import('@/views/Cart.vue'), meta,
		},
		{
			path: '/checkout', name: 'Checkout', component: () => import('@/views/Checkout.vue'), meta,
		},
		{
			path: '/payment', name: 'Payment', component: () => import('@/views/Payment.vue'), meta,
		},
		{
			path: '/popmarket', name: 'PopMarket', component: () => import('@/views/Popmarket.vue'), meta,
		},
		{
			path: '/book/:id', name: 'BookPage', component: () => import('@/views/Bookpage.vue'), meta,
		},

		{
			path: '/blog',
			name: 'Blog',
			component: () => import('@/views/Blog.vue'),
			meta,
		},
		{
			path: '/blog/:id',
			name: 'BlogItem',
			component: () => import('@/views/Blogpage.vue'),
		},
		{
			path: '/success',
			name: 'Success',
			component: () => import('@/components/Upsells.vue'),
			meta,
		},
		{
			path: '/failure',
			name: 'Failure',
			component: () => import('@/components/Failure.vue'),
			meta,
		},

		{
			path: '/success-sale',
			name: 'Upsells',
			component: () => import('@/components/Upsells.vue'),
			meta,
		},

		{
			path: '/upsell-pop',
			name: 'UpsellPop',
			component: () => import('@/components/UpsellPop.vue'),
			meta,
		},

		{
			path: '/upsell-pop2',
			name: 'Popmarket',
			component: () => import('@/views/Popmarket.vue'),
			meta,
		},
	],
	scrollBehavior(to) {
		if (to.hash) {
			return {
				behavior: 'smooth',
				el: to.hash,
				offset: { top: 0, left: 0 },
			};
		}
		return { top: 0, left: 0 };
	},
});

export default router;
