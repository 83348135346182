<template>
	<div v-if="timeLeft > 0" class="CountDown" style="color: red">
		<div>
			{{ ss }}
			<span>שניות</span>
		</div>
		<div>
			{{ mm }}
			<span>דקות</span>
		</div>
		<div>
			{{ HH }}
			<span>שעות</span>
		</div>
		<div>
			{{ daysLeft }}
			<span>ימים</span>
		</div>
	</div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useNow } from '@vueuse/core';

const now = useNow({ interval: 1000 });

const targetDate = ref(new Date(now.value.getFullYear(), 3, 23, 0, 0));

const timeLeft = computed(() => targetDate.value.getTime() - now.value.getTime());

const oneSecond = 1000;
const oneMinute = oneSecond * 60;
const oneHour = oneMinute * 60;
const oneDay = oneHour * 24;

const daysLeft = computed(() => Math.floor(timeLeft.value / oneDay));
const HH = computed(() => Math.floor(timeLeft.value / oneHour) % 24);
const mm = computed(() => Math.floor(timeLeft.value / oneMinute) % 60);
const ss = computed(() => Math.floor(timeLeft.value / oneSecond) % 60);
</script>

<style>
.CountDown {
	display: flex;
	gap: 1rem;
	justify-content: center;
	line-height: 0.8;
	font-size: 120px;
}

.CountDown div {
	width: 90px;
}

.CountDown span {
	display: block;
	font-size: 40px;
}
@media screen and (max-width: 767px) {
	.CountDown {
	font-size: 80px;
}
.CountDown div {
	width: 70px;
}
.CountDown span {
	font-size: 30px;
}
}
</style>
