<template>
	<div @click="open = true" class="faq-box" :class="{ openfaq: open }">
		<div class="faq-icon-wrap" @click.stop="open = !open">
			<img alt="close" src="@/assets/faq-a.svg" class="faq-icon">
			<img alt="open" src="@/assets/faq-p.svg" class="faq-icon plus">
		</div>
		<p class="txt20 bold">
			{{ qna?.question }}
		</p>
		<div class="faq-a" v-html="qna?.answer" />
	</div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

interface Qna {
	question: string;
	answer: string;
}
defineProps<{
	qna: Qna
}>();
// const qna = ref<Qna>();
const open = ref(false);
</script>
