<template>
	<Carousel v-bind="config" dir="ltr" ref="bookSlide" class="book-list owl-carousel owl-theme">
		<router-link :to="`/book/${book._id}`" class="book-box book3d-lite" v-for="book in books" :key="book._id">
			<div class="book">
				<div class="book-grad" />
				<img v-if="book.cover.imageURL" :alt="book.name" :src="book.cover.imageURL">
				<img v-else :alt="book.name" src="../assets/fallback.jpg">
			</div>
			<p class="bookname txt30 mt-10px">
				{{ book.name }}
			</p>
			<p class="txt20 desc">
				{{ book.shortDescription }}
			</p>
		</router-link>
	</Carousel>
</template>

<script setup lang="ts">
import { Carousel } from 'bgl-v-owl-carousel';
import { localData } from '@/data';

const { books } = localData;

const items = window.innerWidth / 220;

const config = {
	loop: true,
	margin: 0,
	nav: false,
	autoplay: true,
	autoplayTimeout: 4000,
	autoplayHoverPause: false,
	items,
	autoWidth: true,
	dots: false,
	slideTransition: 'linear',
	autoplaySpeed: 4000,
	animateIn: true,
};
</script>

<style>
.book-list {
	min-height: 440px;
}

.book-list.owl-carousel {
	padding-top: 44px;

}

.book-list {
	direction: ltr;
}

.book-list .owl-stage-outer {
	padding-top: 20px;
}

.book-list .book-box:hover {
	transform: translateY(-15px);
}

.desc {
	opacity: 0;
	font-size: 14px;
	transition: all ease 0.5s;
}

.book-box:hover .desc {
	opacity: 1;
}

.nav-btn img {
	width: 16px;
	height: 16px;
	margin-left: 3px;
}

.nav-btn.next-slide img {
	width: 16px;
	height: 16px;
	margin-left: -3px;
}

.nav-btn {
	text-align: center;
	width: 30px;
	height: 30px;
	transform: translateY(-40px);
	transition: 200ms all ease;
	border-radius: 100%;
	cursor: pointer;
}

.nav-btn:hover {
	background: #edeaf3;
}

.nav-btn:active {
	background: #edeaf3;
	filter: brightness(90%);
}
</style>
