import { defineStore } from 'pinia';
import { api } from './api';

interface User {
  agreementToAcceptMail: boolean;
}

interface Coupon {
  discount: number;
  name: string;
  coupon: string;
  // name?: Product[];
}
interface Transaction {
  [key: string]: any;
}

interface Product {
  author: string;
  description: string;
  id: number;
  imgLink: string;
  name: string;
  price: number;
  priceOrg: number;
  upsale1: boolean;
  upsale2: boolean;
  upsale3: boolean;
  [key: string]: any;
}

interface Cart {
  coupon?: Coupon | 'error' | 'none';
  products: CartItem[];
  cartId?: number | string;
  name: string,
  lastName: string,
  entranceBuilding?: string,
  agreesMailing: boolean,
  phone: string,
  street: string,
  company: string,
  floor: string,
  apartmentType: string,
  email: string,
  apartment: string
  city: string,
  comments?: string,
  zip: string,
}

interface Charges {
  title: string;
  amount: number;
}

interface CartItem {
  id: number;
  instead?: number;
  price?: number;
  quantity?: number;
  options?: Option[];
  [key: string]: any;
}

interface Option {
  selected: boolean;
  addition?: number;
  title: string;
  [key: string]: any;
}

export const useStore = defineStore({
  id: 'store',
  state: () => ({
    products: [] as Product[],
    notification: '',
    user: { agreementToAcceptMail: true } as User,
    transaction: {} as Transaction,
    upsellProducts: [],
    addedUpsells: [] as Product[],
    maxUpsells: 4,
    cartWarning: '',
    cart: {
      coupon: {} as Coupon | 'error',
      products: [],
      name: '',
      lastName: '',
      agreesMailing: false,
      phone: '',
      street: '',
      company: '',
      floor: '',
      apartmentType: '',
      email: '',
      apartment: '',
      city: '',
      zip: '',
    } as Cart,
    peterson: '',
  }),
  getters: {
    totalAmount() {
      const discount = this.cart?.coupon !== 'error' && this.cart?.coupon !== 'none' ? this.cart?.coupon?.discount : 0;
      const totalAmount: number = this.cart.products.reduce((runningTotal, currentValue) => {
        const amount = currentValue.price || 0;
        const copies = currentValue.quantity || 1;

        return runningTotal + amount * copies;
      }, 0) - Math.abs(discount || 0);
      return totalAmount.toLocaleString('he-IL');
    },
  },
  actions: {
    addShibolet() {
      const shibolet = this.products.find((p) => p.id === 0);
      this.addToCart(shibolet as CartItem);
    },
    async getProducts() {
      this.products = await api('getProducts');
    },
    toggleUpsell(product: Product) {
      const index = this.addedUpsells.findIndex((p) => p.id === product.id);
      if (index > -1) this.addedUpsells.splice(index, 1);
      else if (this.addedUpsells.length < this.maxUpsells) this.addedUpsells.push(product);
    },
    isInCart(product: Product) {
      return this.cart.products.find((i) => i.id === product.id);
    },
    async getPaymentLink() {
      return api('getPaymentLink', { cartId: this.cart.cartId });
    },
    addToCart(item: CartItem) {
      item.quantity = item.quantity || 1;
      const exists = this.cart.products.find((i) => i.id === item.id);
      if (exists) return;
      this.cart.products.push(item);
      // @ts-ignore
      window.fbq?.('track', 'AddToCart', { value: item.price, currency: 'ILS' });
      // else exists.quantity = (exists.quantity || 1) + 1;
    },
    async postUpsell(upsellProducts: Product[]) {
      const { cartId } = this.cart;
      let products: any = upsellProducts.map((p) => (p.id));
      products = JSON.stringify(products);
      products = encodeURIComponent(products);
      await api('upsale3', { cartId, products });
    },
    async setCart() {
      const cart: Record<string, any> = { ...this.cart };
      if (!cart?.coupon?.discount) delete cart?.coupon;
      this.cart = await api('setCart', { cart });
    },
    removeFromCart(item_id: number) {
      const index = this.cart.products.findIndex((item) => item.id === item_id);
      this.cart.products.splice(index, 1);
    },
    clearCoupon() {
      this.cart.coupon = 'none';
    },
    notify(msg: string) {
      this.notification = msg;
      this.clearNotify();
    },
    clearNotify() {
      setTimeout(() => (this.notification = ''), 4000);
    },
    async getCart(cartId: string = '19766de1-e8bf-4228-bd1b-29ed35dfe5af') {
      this.cart = await api('getCart', { cartId });
    },
    async applyCoupon(coupon?: string) {
      this.cart.coupon = await api('getCoupon', { coupon });
    },

    async submitLead(form: any) {
      return undefined;
    },
  },
});
