<template>
	<div class="footer">
		<a href="" class="footer-logo"> <img alt="שיבולת" src="@/assets/logo-white.svg"></a>
		<div>
			<router-link to="/books" class="footer-link">
				הספרים שלנו
			</router-link>
			<!-- <router-link to="/blog" class="footer-link">
				בלוג שיבולת
			</router-link> -->
			<router-link to="/recommendations" class="footer-link">
				ממליצים עלינו
			</router-link>
			<router-link to="/about" class="footer-link">
				מי אנחנו
			</router-link>
		</div>
		<p class="small-footer-txt">
			קינג ג'ורג' 20, ירושלים <span>|</span>
			<a href="mailto:desk@sellameir.com"> desk@sellameir.com</a>
			<span>|</span>
			054-2969899
			<br>
			שעות המענה הן בימים א-ה בין השעות 10:00-16:00
		</p>
		<router-link to="terms" class="small-footer-txt mb-20px">
			תנאי שימוש
		</router-link>
		<router-link to="accessibility" class="small-footer-txt mb-20px">
			הצהרת נגישות
		</router-link>
		<div>
			<a href="https://www.youtube.com/channel/UCI63vlrld2HAcUKYVrDnOLw" target="_blank" class="soc-link">
				<img alt="youtube" src="@/assets/oval-youtube.svg"></a>

			<a href="https://www.facebook.com/shiboletpress" target="_blank" class="soc-link">
				<img alt="facebook" src="@/assets/oval-facebook.svg"></a>

			<img class="bagel" alt="bagel studio" src="@/assets/1bagelstudio_logo_white.svg">
		</div>
	</div>
</template>
<script setup lang="ts">

</script>
