<template>
	<router-view />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from './stores';

const route = useRoute();
const router = useRouter();
const store = useStore();
onMounted(async () => {
	// const cart = localStorage?.getItem('shibolet');
	// if (cart) {
	// 	store.cart = JSON.parse(cart);
	// }

	await store.getProducts();
	const cartId = route.query.cart as string;
	if (cartId) {
		await store.getCart(cartId);
		router.push('/cart').catch(console.error);
	}
});

// watch(
// 	() => store.cart,
// 	(cart) => {
// 		// localStorage?.setItem('shibolet', JSON.stringify(cart));
// 	},
// 	{ deep: true },
// );
</script>

<style src="./main.css"></style>
