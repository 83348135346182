<template>
  <button @click='addToCart' class='btn' style="z-index: 999;">
    {{ value || 'הצטרפו כמנויים' }}
  </button>
</template>

<script setup lang="ts">
import { useRouter, useRoute } from 'vue-router';
import { useStore } from '@/stores';

defineProps<{
  value?: string;
}>();

const router = useRouter();
const route = useRoute();
const store = useStore();
const addToCart = async () => {
  store.addShibolet();
  if (route.path !== '/cart') await router.push('/cart');
};
</script>
