<template>
  <div class="top-nav">
    <button type="button" class="menu-btn" @click="opennavbar = true">
      <img alt="Menu" height="23" src="@/assets/menu.svg">
    </button>
    <router-link to="/" class="top-logo-wrap">
      <img alt="שיבולת" height="55" width="180" src="@/assets/logo-wide.svg">
    </router-link>
    <div class="flex gap-1">
      <AddToCart class="desktop" value="אני רוצה להיות מנוי!" />
      <router-link class="top-cart" to="/cart">
        <img height="24" alt="Cart" class="cart-white" src="@/assets/cart-white.webp">
        {{ store.cart?.products?.length || 0 }}
      </router-link>
    </div>
    <div class="menu" :class="{ openmenu: opennavbar }">
      <div class="close-menu-btn" @click="opennavbar = false">
        <img alt="Close" src="@/assets/close-white.svg" class="">
      </div>
      <!-- <router-link to="/netanyahu" class="bibi-menu-wrap">
				<img alt="Bibi Book" src="@/assets/bibi-book-1.webp" class="small-menu-book">
				<div class="book-menu-txt-wrap">
					<p style="color: #5c32a8">
						ביבי:<br>סיפור חיי
					</p>
				</div>
			</router-link> -->
      <br>
      <br>
      <router-link to="/books" class="nav-link">
        הספרים שלנו
      </router-link>
      <!-- <router-link to="/blog" class="nav-link">
				בלוג שיבולת
			</router-link> -->

      <router-link to="/recommendations" class="nav-link">
        ממליצים עלינו
      </router-link>
      <router-link to="/about" class="nav-link">
        מי אנחנו
      </router-link>
      <AddToCart class="white for-menu" />
    </div>
  </div>
</template>
<script setup lang="ts">import { ref as _ref } from 'vue';

import { AddToCart } from '@/components';

import { useStore } from '@/stores';

const store = useStore();

const opennavbar = _ref(false);
</script>
<style>
.cart-white img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.signup-btn {
  margin-inline-end: 1rem;
}

@media screen and (max-width: 767px) {
  .signup-btn {
    display: none;
  }
}
</style>
