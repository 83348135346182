<template>
	<div>
		<Mainmenu />

		<!-- פופאפ צדדי -->
		<div
			class="side-pop " :class="{ sideopen: sidePopup }"
		>
			<button @click="sidePopup = false" type="button" class="close-pop-circle">
				<img src="@/assets/close.svg" alt="סגירה">
			</button>
			<div style="text-decoration: none;">
				<img
					src="../assets/murray-trump.png" alt="murray trump"
					:style="
						`width: 200px;
          margin-top: -30px;
          filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.15));`"
				>
				<p class="countdown-txt">
					<b>
						הטבה מיוחדת ל-48 שעות:
					</b>
					<br>
					עושים מנוי שיבולת ומקבלים את ספרו של דונלד טראמפ במחיר שלא יחזור
				</p>
				<AddToCart class="btn" value="לרכישת מנוי" />
			</div>
		</div>
		<!-- עד כאן -->

		<div class="popup-wrap" :class="{ openpop: openPopup }">
			<div class="popup side-img">
				<picture class="side-pop-img">
					<source srcset="../assets/pop-side.webp" type="image/webp" >
					<source srcset="../assets/pop-side.jpg" type="image/jpg" >
					<img alt="ספרי שיבולת" src="../assets/pop-side.jpg" >
				</picture>
				<button @click="openPopup = false" type="button" class="close-pop-circle">
					<img alt="" src="@/assets/close.svg" >
				</button>
				<div class="center-txt">
					<!-- <img width="180" src="https://images-2-gvwk7ffjaa-uc.a.run.app?imageID=cdvkmf223aks73e74dhg.png" alt=""> -->
					<p class="txt30 balance mt-10px">
						תודה ל-5,421
						<br >
						המנויים החדשים של שיבולת!
					</p>
					<button class="btn mt-20px">
						לחצו כאן להצטרפות
					</button>
				</div>
			</div>
		</div>
		<div class="center-txt min500">
			<!-- <h1 class="txt72 mb-10px mt-30px px-30px">
				חוזרים ליסודות. חושבים את ישראל מחדש.
			</h1>
			<p class="txt24 mb-20px px-30px">
				מצטרפים לספריית שיבולת, ומקבלים ספרים פורצי דרך לפני כולם. עכשיו במבצע השקה מיוחד!
			</p> -->

			<h1 class="txt72 tzar mt-10px mb-0px">
				מנויי שיבולת מקבלים ראשונים את רב המכר החדש של דאגלס מאריי!
			</h1>
			<!-- <h1 class="txt24 mb-10px mt-0px px-30px">
				חוזרים ליסודות. חושבים את ישראל מחדש.
			</h1> -->
			<p class="txt40 mb-20px px-30px">
				הזדמנות אחרונה להצטרף לקהילת הספר הגדולה בישראל
			</p>
			<div class="txt40 m-0 mb-30px" id="countdown">
				<CountDown />
			</div>

			<BookSlide />
		</div>
		<AddToCart class="mobile btn-home" value="אני רוצה להיות מנוי!" />
		<div class="home-store-wrap">
			<div class="home-product">
				<img src="../assets/COMP2.png" alt="מנוי שיבולת" >
				<div>
					<div class="buy-price-wrap">
						<p class="txt30-wide bold">
							<!-- מנוי שיבולת - ימים אחרונים למבצע! -->
							מנוי לספריית שיבולת 2024
						</p>
						<p class="txt60 price">
							449 ש"ח
						</p>
						<p class="txt30 price">
							<br >
							במקום
							<del style="text-decoration-color: red"> 1,190 ש"ח </del>
						</p>
					</div>
					<div class="txt17">
						<p>
							קבלו את עשרת הספרים המדוברים של השנה לפני כולם וטרם ההגעה לחנויות. בנוסף, כתב העת
							השילוח יצורף לכם מתנה בכל משלוח. זו ההזדמנות האחרונות לקחת חלק בשיחה ובמפגשים של
							קהילת הספר הגדולה בישראל!
						</p>
					</div>
					<AddToCart value="הצטרפו עכשיו למנויי שיבולת!" />
					<br >
				</div>
			</div>
		</div>
		<div class="sec purchase" id="purchase">
			<div class="w1170 subs-flex">
				<div class="sub-txt-wrap">
					<h3 class="txt40 lh-12 mb-30px center-txt normal">
						<!-- הצטרפו עכשיו לספריית המנויים -->
						<span class="bold txt80"> מה מקבלים במנוי?</span>
					</h3>
					<p class="txt20">
						עושים עכשיו מנוי לספריית שיבולת ומקבלים במשלוח עד הבית את רב המכר החדש של דאגלס מאריי "הקרב על המערב", לצד תשעה ספרים נוספים שנוגעים בשאלות החשובות ביותר שעל סדר היום הלאומי.
					</p>
					<router-link class="small-link mb-30px white" to="/about">
						קראו עוד
					</router-link>
					<p class="txt40 bold">
						מה בנוסף?
					</p>
					<div class="subs-list">
						<p class="txt20 checkme">
							ספר מתנה בהפתעה במהלך השנה.
						</p>
						<p class="txt20 checkme">
							אירועי ספר מיוחדים לחברי המועדון.
						</p>
						<p class="txt20 checkme">
							השתתפות בשיחה שמשנה את החברה בישראל.
						</p>
					</div>
				</div>
				<div class="subs-books-wrap">
					<div class="subs-small-books">
						<div class="ani-book" v-for="book in [...books, ...books]" :key="book._id">
							<div class="book-grad" />
							<img v-if="book.cover.imageURL" :alt="book.name" :src="book.cover.imageURL" >
							<img v-else src="../assets/fallback.jpg" alt="" >
						</div>
					</div>
				</div>

				<div class="subs-oval">
					<p class="txt24">
						עכשיו במבצע השקה
					</p>
					<p class="txt20" style="text-decoration: line-through; text-decoration-color: red">
						1,190 ₪
					</p>
					<p class="oval-num">
						449 ₪ בלבד
					</p>
					<span style="font-size: smaller"> תשלום חד פעמי למנוי שנתי </span>
				</div>
				<img class="bg-logo" src="@/assets/logo-ver-white.svg" alt="Shibolet" >
				<AddToCart class="subs-btn" value="לרכישת מנוי שיבולת" />
			</div>
		</div>
		<iframe
			class="yt-vid"
			src="https://www.youtube.com/embed/RHa87GC6q2E?si=rEDj-wroYEDwMgq5"
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			referrerpolicy="strict-origin-when-cross-origin"
			allowfullscreen
		/>
		<Recommendations />
		<div class="sec dark">
			<div class="w900 flex">
				<div class="work-txt">
					<h3 class="txt40">
						מנוי שיבולת <b>6</b> איך זה עובד?
					</h3>
					<p class="txt20">
						כל חודשיים יוצא משלוח של שני ספרי שיבולת + גיליון כתב העת "השילוח"
					</p>
					<p class="txt20">
						<b>המשלוח הראשון יהיה בחודש אפריל 2024</b>
					</p>
				</div>
				<div class="work-ovel">
					<img alt="" src="@/assets/works-icon.svg" >
					<p class="txt30-wide">
						הזמינו עכשיו!
					</p>
				</div>
			</div>
		</div>
		<div class="sec infographic">
			<div class="w900 mt-40px mb-40px icons-flex">
				<div class="icon-wrap">
					<div class="icon-oval">
						<img alt="" src="@/assets/benefits-icon-1.svg" >
					</div>
					<p>מחיר מוזל</p>
				</div>
				<div class="icon-wrap">
					<div class="icon-oval">
						<img alt="" src="@/assets/benefits-icon-3.svg" >
					</div>
					<p>קבלת הספרים חודש לפני יציאתם לחנויות</p>
				</div>
				<div class="icon-wrap">
					<div class="icon-oval">
						<img alt="" src="@/assets/benefits-icon-4.svg" >
					</div>
					<p>ספרו החדש של ג'ורדן פיטרסון נכלל במנוי</p>
				</div>
			</div>
		</div>
		<div class="sec center-txt">
			<div class="small-line" />
			<h3 class="txt40">
				שאלות נפוצות
			</h3>
			<Faq v-for="qna in localData.faq" :key="qna._id" :qna="qna" />
		</div>
		<Botfooter />
	</div>
</template>

<script setup lang="ts">import { ref as _ref } from 'vue';

import { onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Botfooter, Mainmenu, Faq } from '@/components';
import BookSlide from '../components/BookSlide.vue';
import Recommendations from '@/components/Testimonials.vue';
import AddToCart from '../components/AddToCartBtn.vue';
import CountDown from '@/components/CountDown.vue';

import { localData } from '@/data';
import { useStore } from '@/stores';

const router = useRouter();
const route = useRoute();

const bookstore = _ref<any[]>([]);
const sidePopup = _ref(true);
// const bibiPopup = $ref(false);
let openPopup = _ref(false);
// const images = [...Array(10)].map((_, i) => i + 1).reverse();
const store = useStore();

const { books } = localData;

// async function addToCart(product: any) {
// 	store.addToCart(product);
// 	await router.push('/cart');
// }

// async function addBibiToCart() {
// 	if (route.path !== '/cart') await router.push('/cart');
// }

// function scrollToPurchase() {
// 	const element = document.querySelector('#purchase');
// 	element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
// }

function onMouseOut(event: MouseEvent) {
	const target = event.target as HTMLElement;
	if (
		event.clientY < 50 &&
		event.relatedTarget === null &&
		target?.nodeName.toLowerCase() !== 'select'
	) {
		// Remove this event listener
		document.removeEventListener('mouseout', onMouseOut);
		document.onkeydown = (evt) => {
			evt = evt || window.event;
			const isEscape = evt.key === 'Escape' || evt.key === 'Esc' || evt.keyCode === 27;
			if (isEscape) openPopup.value = false;
		};
		// const callme = localStorage?.getItem('callme') || '1';
		// localStorage?.setItem('callme', String(+callme + 2));
		// Show the popup
		openPopup.value = true;
	}
}

onMounted(async () => {
	// const homepageItems = localData.products.filter((p: any) => p.homepage);
	// bookstore.push(...homepageItems);
	// setTimeout(() => (sidePopup = true), 1500);
	// const callme = parseInt(localStorage.getItem('callme') || '0');
	// if (callme < 4) document.addEventListener('mouseout', onMouseOut);
});
</script>
