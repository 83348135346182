import axios from 'axios';

export const api = async (operation: string, data?: Record<string, any>) => {
  let payload = `operation=${operation}`;
  if (data) Object.entries(data).forEach(([key, value]) => payload += `&${key}=${valueToString(value)}`);
  const { data: dataResponse } = await axios.post('https://effective-soft.co.il/XZone/ShiboletAPI', payload);
  return dataResponse;
};

const valueToString = (val: any): string => {
  if (val === true) return 'true';
  if (val === false) return 'false';
  if (typeof val === 'string') return val;
  if (typeof val === 'number') return val.toString();
  if (val instanceof Array) return val.map(valueToString).join(',');
  if (val instanceof Object) return encodeURIComponent(JSON.stringify(val));
  return val;
};
